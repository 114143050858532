import React from "react"
import classNames from "classnames"
import { SectionSplitProps } from "utils/SectionProps"
import SectionHeader from "components/layout/partials/SectionHeader"
import Image from "components/elements/Image"

import animx_image from "assets/images/graph_editor.png"
import wgen_image from "assets/images/hair_1.png"
import scenelib_image from "assets/images/light_2.png"
import LayoutDefault from "layouts/LayoutDefault"

import SolutionFeaturesTiles from "components/layout/en/SolutionFeaturesTiles"
import Cta from "components/layout/en/Cta"

const propTypes = {
  ...SectionSplitProps.types,
}

const defaultProps = {
  ...SectionSplitProps.defaults,
}

const Index = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  )

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  )

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  )

  const sectionHeader = {
    title: [
      <>
       Wizart <span className="text-color-primary">DCC</span> Platform <br/>
       for  <span className="text-color-primary">Lookdev</span>
      </>
    ],
    paragraph:
      <> <span className="text-color-primary">WSYWIG</span> lookdev directly your pipeline data </>
  }

  return (
    <LayoutDefault {...props}>
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={splitClasses}>
            <SolutionFeaturesTiles/>
              {/* <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    Open Source Animation
                  </div>
                  <h3 className="mt-0 mb-12">ANIMX</h3>
                  <p className="m-0">
                    AnimEngine Extension for Wizart DCC, uses the{" "}
                    <a href="https://github.com/Autodesk/animx">AnimX</a>{" "}
                    library to evaluate animation curves. Artists can author
                    rich animation curves per attribute, preview the result in
                    the viewport bake the result to time sampled USD animation.
                    Artists can edit animation keys, tangents interactively in
                    the Graph Editor Panel.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <Image
                    src={animx_image}
                    alt="Features split 01"
                    width={528}
                    height={396}
                  />
                </div>
              </div>

              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-right"
                  data-reveal-container=".split-item"
                >
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    Open Source Hair System
                  </div>
                  <h3 className="mt-0 mb-12">WGEN</h3>
                  <p className="m-0">
                    Wgen is a fully-featured hair system. Curves are scattered
                    on subdivision meshes and support{" "}
                    <a href="https://github.com/AcademySoftwareFoundation/OpenShadingLanguage">
                      OSL
                    </a>{" "}
                    networks to deform or modify hair color using textures. The
                    Wgen is expressed as the full graph in USD, where UsdGeom
                    meshes and curves are inputs, but resulting curves are
                    visualized using Hydra. Wizart DCC extension also has the
                    tools to interactively model hair guides.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <Image
                    src={wgen_image}
                    alt="Features split 02"
                    width={528}
                    height={396}
                  />
                </div>
              </div>

              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    Open Source Lighting Toolset
                  </div>
                  <h3 className="mt-0 mb-12">SCENELIB</h3>
                  <p className="m-0">
                    Scenelib is a functional programming-inspired graph
                    computation engine, with the idea that each scenegraph prim
                    is processed separately and lazily on-demand. Lighting
                    artists can visually create a sequence or shot lighting
                    using the SceneLib node graph and set up multiple renders
                    from the single node graph. The resulting scenegraph data
                    could be rendered using the Non-Hydra backend.{" "}
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-bottom",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <Image
                    src={scenelib_image}
                    alt="Features split 03"
                    width={528}
                    height={396}
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <Cta split topDivider />
    </LayoutDefault>
  )
}

Index.propTypes = propTypes
Index.defaultProps = defaultProps

export default Index